.contact_container{
    width:100%;
  
  }
  .contact_form{
    margin-left: auto;
    margin-right: auto;
    max-width: 30em;
  }

  .contact_error{
    color:$danger;
  }

  .captcha_row{
    display: flex;
    
  }

  .request_success{
    color:$success;
    font-size: larger;
    margin-bottom: 1rem;
  }