.remove_list:hover{

}
.remove_list{
    position: relative;
}

.delete_hover_icon{
    display: none;
}

.remove_list:hover>.delete_hover_icon{
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    color:red;
    justify-content: center;
    align-content: center;
}

.remove_icon{
    height:50px;
    max-height  :50px !important;
    margin-left : auto;
    margin-right: auto;
}