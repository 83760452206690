.table-header-icon{
    margin-left:1rem;
}

.table-pagination{
    margin-top: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
}

.table-pagination button,
.table-pagination select{
    border:$primary 1px solid;
    background-color: $secondary;
    cursor: pointer;
}



.table_button
{
    border:none;
    background-color: inherit;
    cursor: pointer;
    padding: 0.5rem;
    font-size: $size3;
    &:hover{
        color: $secondary;
            background-color: $warning ;
    }
    
}





table tbody tr:nth-child(odd){
    background-color: $secondary;
}

table tbody tr:hover{
    background-color: $primary;
    color:$lightText !important;
}

.table_edit{
    color:$info !important;
}


.delete_user_dialog{
    background-color: $dialog_overlay_background;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.delete_user_dialog_inner{
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
}

.delete_user_dialog_inner_warning{
    color: $danger;
    font-weight: bold;
}

.delete_user_dialog_inner_action_bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap:1rem;
}