.membership_types {
    display              : grid;
    grid-template-columns: 20rem auto 20rem;
    gap                  : 1rem;
}


.membership_types_header {
    font-weight: 700;
}

.memberships_table {
    display              : grid;
    grid-template-columns: auto auto auto auto;
}

.memberships_table>div:nth-child(4n+1) {
    padding-left: 1rem;
}

.memberships_table>div:nth-child(8n+1),
.memberships_table>div:nth-child(8n+2),
.memberships_table>div:nth-child(8n+3),
.memberships_table>div:nth-child(8n+4) {
    background-color: $secondary;
}


.memberships_table_commands {
    display       : flex;
    flex-direction: row;
    align-items   : baseline;
    margin-top    : 1rem;
}

.payments_table {
    display              : grid;
    grid-template-columns: auto auto auto auto auto;

}


.payments_table_row>div:first-child {
    padding-left: 1rem
}

.payments_table_row div {
    padding-top   : 0.2rem;
    padding-bottom: 0.2rem;
}


/*
.payments_table_row:nth-child(odd) div {
    background-color: $secondary;
}

*/
.payments_table_row {
    display: contents;
}

.payments_table_row:hover>div {
    background-color: #dbeaf4;
    cursor          : pointer;
}

.missing_dates {
    display  : flex;
    flex-wrap: wrap;
    gap      : 1rem;
}

.open_payments {
    display  : flex;
    flex-wrap: wrap;
    
}

.open_payment:nth-child(odd) {
    background-color: $secondary;
}

.open_payment {
    display       : flex;
    flex-direction: column;
    gap           : 1rem;
    padding: 1rem;
    padding-bottom: 1rem !important;
}