.new_trainer_form_dialog{
    background-color: $dialog_overlay_background;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 9999;
}

.new_trainer_form_inner{
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 1rem;
    padding: 1rem;
}