.session_command_row {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.delete-button {
  background-color: $danger;
}

.dateSelectContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5rem;
}
