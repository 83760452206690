.private_container {
    display       : flex;
    flex-direction: row;
    width         : 100%;
    //height: 100%;
    //min-height: 100%;



}

.private_main {
    display       : flex;
    flex-direction: column;
    width         : 100%;


}

.private_main_header {
    min-height      : 3em;
    background-color: $ligh-primary;
    margin-bottom   : 1rem;
    box-shadow      : 0 10px 10px -10px rgba(0, 0, 0, 1);
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    padding         : 1rem;
}
@media (max-width:768px) {
    .private_main_header {
        margin-left: 3.5rem;
    }
}


.private_main_header_left {
    font-size: $size2;
}

.private_main_content {}

.logout_btn {
    margin: 1rem;
}

.logout_btn:after {
    @include fa-icon-solid($fa-var-user);
    content  : "\f2f5";
    font-size: $size3;
}

.content-block {
    background-color: $ligh-primary;
    box-shadow      : 5px 5px 5px -5px rgba(0, 0, 0, 1);
    margin          : 2rem 1rem 2rem 5rem;
    padding         : 2rem;
    overflow        : visible;

    .content-block-header {
        color        : $mid-text;
        margin-bottom: 2rem;
    }
}

@media (min-width: 768px) {
    .content-block {
        margin: 2em 5rem 2rem 5rem;
    }

}

.action-block {
    display  : flex;
    gap      : 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}