.topic_list {
    display  : flex;
    flex-wrap: wrap;
    gap      : 1rem;
}

.topic_element {
    border : 1px solid $primary;
    padding: 1rem;
}

.disciplineImage {
    max-width: 80px;
}

.disciplineImg {
    max-width     : 80px;
    margin        : 0 auto;
    display       : flex;
    flex-direction: column;
}

.back{
    margin-left: 5rem;
    padding:0.5rem;
    background-color: $hightlight-bg;
    color: $lightText;
    border: 1px solid $hightlight-bg; 
    font-size: 1.2rem;
    &:hover{
        background-color: $dark-primary;
        color: $dark-text;
    }
}