.product_table_container{
    display: flex;
    justify-content: space-around;
}

.product_title {
    font-weight : bold;
    font-size   : $size4;
    border-top  : 1px solid black;
    border-left : 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    text-align: center;
    border-radius: 1rem 1rem 0 0;
}

.product_title_placeholder {
    font-weight: bold;
    font-size  : $size4;
}

.product_table {
    display              : grid;
    grid-template-columns: repeat(3, auto);
    column-gap           : 1rem;
    width: fit-content;
}

.product_table>div {
    align-self: center;
    width     : 100%;
    padding: 1rem;
}

.product_feature {
    color        : $success;
    justify-self : center;
    border-bottom: 1px solid black;
    border-right : 1px solid black;
    border-left  : 1px solid black;
    text-align: center;
}

.price{
    font-weight: bold;
    color: inherit;
}

.product_feature_action{
    border-left : 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    text-align: center;
    border-radius: 0 0 1rem 1rem;
    height:4.5rem;
}


.product_feature_name {
    font-weight  : bold;
    justify-self : end;
    text-align: right;
    border-bottom: none;
}

.subscription_row{
    display: grid;
    grid-template-columns: repeat(3,auto);
    justify-content:space-between;

}

.subscription_title{
    font-weight : bold;
    font-size   : $size5;
}

.current_sub_container{
    margin-bottom: 2rem;
}


.buy_dialog{
    background-color: $dialog_overlay_background;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height:100%;
    display: flex;
    
    justify-content: space-around;
}

.buy_dialog_inner{
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 1rem;
    padding: 1rem;
    position:relative;
    top:5rem;
    height:fit-content;
}

.payment_address_form{
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    margin-bottom: 0.5rem;
}