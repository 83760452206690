.disciplineCard {
  width           : 90px;
  height          : 120px;
  border          : 1px solid $primary;
  border-radius   : 0.25rem;
  margin          : 0.25rem;
  background-color: white;
  justify-content : center !important;
  display         : flex;
  flex-direction  : column;
  overflow:hidden;
  
}


.trainerCard {
  width           : 90px;
  height          : 120px;
  border          : 1px solid $primary;
  border-radius   : 0.25rem;
  margin          : 0.25rem;
  background-color: white;
  justify-content : center !important;
  display         : flex;
  flex-direction  : column;
  cursor          : pointer;
  overflow:hidden;
}

.trainerCard-Title {
  margin-left : auto;
  margin-right: auto;
  width       : fit-content;
  font-weight : 700;

}

.card-img-top {
  max-height: 50px !important;
}

.cardImage {
  max-height  :50px !important;
  margin-left : auto;
  margin-right: auto;
}

.trainerCard:hover,
.disciplineCard:hover {
  background-color: $secondary;
  cursor          : pointer;
}

.disciplineCardIcon {
  max-width : 80px;
  max-height: 80px;

}

.disciplineCardName {
  font-weight: bold;
}


.discipline-card-body {
  display       : flex;
  flex-direction: column;

}

.discipline-card-body>div {
  width       : fit-content;
  margin-left : auto;
  margin-right: auto;
}

.descipline-card-title {
  font-weight: 700;
}

.trainerCard-card-grade {
  width       : fit-content;
  margin-left : auto;
  margin-right: auto;
  text-align: center;
}



.selectable_card{
  width           : 90px;
  height          : 120px;
  padding:2rem;
  border          : 1px solid $primary;
  border-radius   : 0.25rem;
  margin          : 0.25rem;
  background-color: white;
  justify-content : center !important;
  display         : flex;
  flex-direction  : column;
  overflow:hidden;
}

.card_list{
  display: flex;
  flex-direction  : row;
  flex-wrap: wrap;
}



.dialog_card{
  background-color: $dialog_overlay_background;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 9999;
}

.dialog_card_inner{
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: 1rem;
  padding: 1.5rem;
}