.avatarEditContainer {
  position: 'relative';
  width   : '80px';
  cursor  : pointer;
}

.avatar {
  width: 150px;
}

.avatarEditContainer:hover .image {
  opacity: 0.3;

}

.avatarEditContainer:hover .avatarEditOverlay {
  opacity: 1;
}

.avatarEditOverlay {
  transition     : .5s ease;
  opacity        : 0;
  position       : relative;
  top            : -2em;
  left           : 1em;
  //transform: translate(-50%, -40%);
  //-ms-transform:  translate(-50%, -40%);
  text-align     : right;
}

.avatarEditOverlay img {
  width: 40px;
}



.camera-capture-button {
  width : 50px;
  height: 50px;

  border       : solid white 3px;
  border-radius: 25px;

  background-color: none;
  display         : flex;
  align-items     : center;
  justify-content : center;
  padding         : 0;

  &:hover {
    border: solid white 3px;
  }
}

.camera-capture-button-circle {
  background-color: white;
  width           : 40px;
  height          : 40px;
  border-radius   : 20px;
}



.camera-capture-button-cross,
.camera-capture-button-change-camera{
  background-color: white;
  width           : 40px;
  height          : 40px;
  border-radius   : 20px;
  display         : flex;
  align-items     : center;
  justify-content : center;
}
.camera-capture-button-cross>img,
.camera-capture-button-change-camera>img{
  width:30px;

}

.camer-container {
  width   : 250px;
  position: relative;
}

.camera-capture-image {
  width: 250px;
}

.capture-controls {
  display        : flex;
  flex-direction : row;
  justify-content: center;
  width          : 100%;
  position       : absolute;
  bottom         : 0;
  left           : 0;
}