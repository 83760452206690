.add_members_disciples{
    display: grid;
    grid-template-columns: auto auto auto auto ;
    row-gap: 1rem;
    column-gap: 1rem;
    margin-bottom: 1rem;
}

.add_members_disciplines_header{
    font-weight: 700;
}