.splash_logo {
    max-width: 25rem;

}

.splash_logo_container {
    margin-left : auto;
    margin-right: auto;
    width       : fit-content;
}

.splash_title {
    text-align   : center;
    width        : 100%;
    margin-top   : 2rem;
    margin-bottom: 2rem;
    color        : $mid-text;
}

.spash_container {
    display         : flex;
    flex-direction  : row;
    flex-wrap       : wrap;
    gap             : 4rem;
    margin-left     : auto;
    margin-right    : auto;
    margin-bottom   : 5rem;
    max-width       : 1024px;
    background-color: $ligh-primary;
    box-shadow      : 5px 5px 5px -5px rgba(0, 0, 0, 1);
    padding         : 2rem;
    overflow        : visible;

    .splash-content-block {
        width             : calc(50% - 2rem);
        background-color  : $ligh-primary;
        height            : 100%;
        //margin          : 2rem 1rem 2rem 5rem;
        padding           : 2rem;
        overflow          : visible;
    }

    .splash-product-header {
        color        : $mid-text;
        margin-bottom: 2rem;
        font-weight  : 800;
        font-size    : $size3;
    }
}

.register-content-block {

    background-color  : $ligh-primary;
    height            : 100%;
    //margin          : 2rem 1rem 2rem 5rem;
    margin-left       : auto;
    margin-right      : auto;
    padding           : 2rem;
    overflow          : visible;
}

.splash_main_header {
    min-height      : 5em;
    background-color: $ligh-primary;
    margin-bottom   : 1rem;
    box-shadow      : 0 10px 10px -10px rgba(0, 0, 0, 1);
    display         : flex;
    justify-content : space-between;
    align-items     : center;
    padding         : 1rem;
}

.splash_main_header_right {
    display       : flex;
    flex-direction: row;
    gap           : 1rem;
}

.icon_li_splash {
    display       : flex;
    flex-direction: row;
    gap           : 1rem;
    align-items   : baseline;
    color         : $hightlight-bg;
    font-size     : $size4;
}

.fetures_list {
    list-style    : none;
    margin        : 0;
    margin-left   : auto;
    margin-right  : auto;
    padding       : 0;
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;
    width         : fit-content;
    justify-content: center;
    gap           : 1.5rem;

    li {
        position      : relative;
        display       : inline-block;
        padding       : 2rem;
        border        : 1px solid $hightlight-bg;
        min-width     : 15rem;
        max-width     : 15rem;
        display       : flex;
        flex-direction: column;
        align-items   : center;
        justify-content: center;

        * {
            //width      : fit-content;
            text-align : center;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            svg{
                margin-bottom:1rem;
            }
        }
    }


}
.feature_pro_button{
    margin-right: 3rem;
    color   : $warning;
    font-weight: 800;
}


.feature_pro::after {
    //       font-family: "Font Awesome 5 Free"; 
    font-weight: 400;
    font-size  : $size2;
    @include fa-icon-solid($fa-var-user);
    content : '\f005';
    color   : $warning;
    position: absolute;
    right   : -10px;
    top     : -12px;
}


.call_to_action_bar{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: baseline;
}

.call_to_action {
    margin-top: 2rem;
    font-size : $size2;
}


.comparison {
    display              : grid;
    grid-template-columns: auto auto auto;
    margin-left: auto;
    margin-right:auto;
    
}

.comparison_head {
    position: relative;
    font-weight: 900;
    font-size: $size3;
    text-align: center;
}

.comparison_title{
    font-weight: 600;
    padding:1rem;
    border-bottom: 1px solid $hightlight-bg;
}

.comparison_value{
    text-align: center;
    border-left: 1px solid $hightlight-bg;
    border-bottom: 1px solid $hightlight-bg;
    padding:1rem;
    &.highlight{
     font-weight: 800;   
    }
}

.comparison_action{
    text-align: center;
    padding:1rem;
}

.feature{
    padding       : 2rem;
    border        : 1px solid $hightlight-bg;
    width:100%;
    display       : flex;
    flex-direction: row;
    gap:2rem;
}