.main_nav {
  padding         : 0;
  position        : fixed;
  top             : 0;
  left            : 0;
  z-index         : 9998;
  background-color: $dark-primary;
  height          : 100%;

  .main_nav_list {
    list-style: none;
    padding   : 0;
    margin    : 0;

    li {
      font-size: 1.75rem;
    }
  }

  .main_nav_sub_opener {
    padding: 1rem;
  }

  a,
  a:visited,
  a:active,
  .main_nav_sub_opener {
    color: #ffffff;
    width: 100%;

    li {
      padding: 1rem;
    }

    &:hover {
      background-color: $hightlight-bg;

      li {
        background-color: $hightlight-bg;
      }

    }
  }

}

.icon_li {
  display       : flex;
  flex-direction: row;
  gap           : 1rem;
  align-items   : baseline;
}

.sidebar_icon {
  display  : block;
  width   : 1.6rem;
  overflow : visible;
}

#discipline_opener~label {
  display       : flex;
  flex-direction: row;
  gap           : 1rem;
  align-items   : center;
  cursor        : pointer;
}

#discipline_opener {
  position: absolute;
  left    : -9999px;
  top     : -9999px;

}

#discipline_inner {
  list-style: none;
  padding   : 0;
  margin    : 0;
  display   : none;
  max-height: 0;
  transition: max-height 0.3s ease-out;

  li {
    padding-left: 2rem;
  }
}

#discipline_opener:checked~label {
  background-color: $hightlight-bg;
}

#discipline_opener:checked~#discipline_inner {
  max-height: 100%;
  display   : block;
  transition: max-height 0.3s ease-in;
}


#nav_opener {
  position: absolute;
  left    : -9999px;
  top     : -9999px;
}

#nav_opener~label::before {
  @include fa-icon-solid($fa-var-user);
  content: "\f054";
}

#nav_opener:checked~label::before {
  @include fa-icon-solid($fa-var-user);
  content: "\f053";
}


#nav_opener~label {
  font-size : $size2;
  padding   : 1rem;
  display   : block;
  width     : 100%;
  text-align: right;
}

#nav_opener:checked~.main_nav_list {
  max-height: 100%;
  display   : block;
  transition: max-height 0.3s ease-in;
  min-width : 20rem;
}


.main_nav_list {
  display  : none;
  min-width: 2rem;

}

@media (min-width: 768px) {
  .main_nav {
    padding : 0;
    position: relative;
  }
  .main_nav_list {
    max-height: 100%;
    display   : block;
    transition: max-height 0.3s ease-in;
    min-width : 20rem;
  }
  #nav_opener~label::before {
  display: none;
  }

}

.activeLink {
  background-color: $hightlight-bg;
}

.sidebar_logo{
  max-width: 15rem;
}

.logo_li{
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}