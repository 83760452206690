//fonts
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
$quicksand        : 'Quicksand',
sans-serif;



$primary   : #2885a9;
$secondary : #c4e8f5;
$success   : #75a147;
$info      : #211e26;
$warning   : #f2910f;

$light     : rgba($primary, .1);

$dark        : #2885a9;
$lightText   : #ffffff;
$border-color:$info;

$dialog_overlay_background: rgba(0, 0, 0, .5);
$table-striped-bg         : rgba($primary, .5);


//SIZES
$unit           : 12px;
$font-base-size : 12px;
$small          : 10px;
$size2          :28px;
$size3          :18px;
$size4          :14px;
$size5          :12px;

//page max width
$max-size: 100%;

//new colors
$dark-primary:#2885a9;
$mid-primary :#c4e8f5;
$ligh-primary:#f0f9fc;

$hightlight-bg:#1d607a;

$light-text:#ffffff;
$mid-text  :#6e7882;
$dark-text :#000000;
$danger    : #f44336;
$dark-danger :hsl(4, 90%, 20%);


@import "./fa/fontawesome.scss";
@import "./fa/solid.scss";

@import './bootstrap/bootstrap';
//$body-bg: $white !default;
$body-bg:$mid-primary;
@import './sidebar.scss';
@import './header.scss';
@import './member-table.scss';
@import './new-trainer-form.scss';
@import './cards.scss';
@import './loading.scss';
@import './login.scss';
@import './forms.scss';
@import './avatar.scss';
@import './discipline-grades.scss';
@import './selectable.scss';
@import './subscriptions.scss';
@import './private_layout.scss';
@import './selectable_student_list.scss';
@import './discipline.scss';
@import './new-member-form.scss';
@import './session.scss';
@import './discipline-students.scss';
@import './calendar.scss';
@import './spash.scss';
@import './exam.scss';
@import './editable-image.scss';
@import './membership.scss';
@import './contact.scss';

#root {
  min-height    : 100%;
  display       : flex;
  flex-direction: column;
  font-size     : $font-base-size;
  font-family   : $quicksand;
}

html,
body {
  min-height      : 100% !important;
  height          : 100%;
  font-size       : $font-base-size;
  background-color: $body-bg;
  color           : $dark-text;
}

h1 {
  font-size  : $size2;
  font-weight: 700;
}

h2 {
  font-size  : $size3;
  font-weight: 600;
}

h3 {
  font-size    : $size4;
  font-weight  : 500;
  margin-bottom: 0.5rem;
  margin-top   : 1rem;
}

h4,
h5 {
  font-size  : $size5;
  font-weight: 400;
}


a {
  text-decoration: none;
}


.containercentered {
  margin: 0 auto;
  width : fit-content;
}


.row {
  //margin-right: 15px !important;
  //margin-left : 15px !important;
}

.dm_row {
  display: flex;
  gap    : 2rem;
}

.row-h {
  border: solid $primary 1px;
}

.row-h:hover {
  background-color: $primary;
  color           : $lightText;
}

.btnIcon {
  width : 80px;
  cursor: pointer;
}

.btn:hover {
  cursor: pointer;
}

.btnIcon-small {
  height       : 1.2em;
  margin-bottom: 3px; // so icons are in lign with text
}



.topic {
  border       : 1px solid $primary;
  border-radius: 0.25rem;
  padding      : 0.4rem;
  margin       : 0.25rem;
  text-align   : center;
}

.topic:hover {
  background-color: $secondary;
}

hr {
  border-color    : $primary;
  background-color: $primary;
  opacity         : 1;
}

.categoryName {
  font-weight : bold;
  padding-left: 0 !important;
}

.btn-nopadding {
  padding: 0;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

//----------


.root-container {
  width       : 100%;
  max-width   : $max-size;
  margin-left : auto;
  margin-right: auto;
  min-height  : calc(100vh - 3rem);
  height      : 100%;
  display     : flex;
}

.splash-container {
  width: 100%;
}

.footer-container {
  width       : 100%;
  max-width   : $max-size;
  margin-left : auto;
  margin-right: auto;
  height      : 4rem;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  background-color: $dark-primary;
  display: flex;
  flex-direction: row;
  gap:2rem;
  font-size: 1.2rem;
}

.footer-container>span>a {
  color: $lightText;
}

.footer-container>span {
  position: relative;
  bottom  : -1rem;
}

.dm-container {
  width   : calc(100% - 1rem);
  padding : 1rem;
  overflow: visible;
}


.dm-container>a {
  text-decoration: none;
}



.session-row {
  width          : 100%;
  padding        : 0.25rem;
  border         : 1px solid $border-color;
  border-radius  : 0.25rem;
  margin-bottom  : 0.25rem;
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
}



.session-row:hover {
  background-color: $secondary;
}

.discipline-row {
  width          : 100%;
  display        : flex;
  flex-direction : row;
  justify-content: flex-start;
  flex-wrap      : wrap;
}

.student-list-row {
  width          : 100%;
  display        : flex;
  flex-direction : row;
  justify-content: flex-start;
  flex-wrap      : wrap;
}

.grade-row {
  width          : 100%;
  padding        : 0.25rem;
  border         : 1px solid $border-color;
  border-radius  : 0.25rem;
  margin-bottom  : 0.25rem;
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
}

.grade-row:hover {
  background-color: $secondary;
}



.grade-row>*,
.grade-header-row>* {
  flex-basis : 0;
  flex-grow  : 1;
  flex-shrink: 1;
}

.grade-header-row {
  width          : 100%;
  padding        : 0.25rem;
  margin-bottom  : 0.25rem;
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
}