.rbc-event {
    //background-color: $hightlight-bg !important;
    color           : $light-text !important;
    border-radius   : 0 !important;
    border-color    : $hightlight-bg !important;
    border-width    : 1px !important;
    font-size: 1.2rem;
}

.rbc-btn-group>button:hover,
.rbc-event:hover {
    background-color: $dark-primary !important;
    color           : $dark-text !important;
    border-width    : 1px !important;
    border-color    : $hightlight-bg !important;
}

.rbc-btn-group>button {
    background-color: $hightlight-bg !important;
    color           : $light-text !important;
    border-radius   : 0 !important;
    font-size       : 1.2rem !important;
    border-color    : $hightlight-bg !important;
    border-width    : 1px !important;
}

.rbc-btn-group{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    gap:1rem !important;
}