.header {
    padding-bottom: 1rem;
}

.main-nav {
    padding-left : 1rem;
    padding-right: 1rem;
    font-size    : 1.3rem;
    align-items  : baseline;

    .navbar-nav {
        align-items: baseline;
        gap        : 1rem;
    }


    .dropdown-menu {
        background-color: $primary;
        color           : $lightText;
        border          : 0px solid $info;
        border-radius   : 0.25rem;
    }

    .nav-item,
    .nav-item>a,
    .dropdown-item,
    .dropdown-item>a {
        color: $lightText;
    }

    .nav-item>a{
        padding: 6px;
    }

    .nav-item>a:hover,
    .dropdown-item:hover,
    .dropdown-item>a:hover {
        background-color: $secondary;
        color           : $primary;
    }

    @media (min-width: 768px) {
        .navbar-expand-md .navbar-nav {
            gap: 1rem;
        }
    }

}

.secondary-nav {
    padding-left : 1rem;
    padding-right: 1rem;
    font-size    : 1.3rem;
    align-items  : baseline;


    .navbar-nav {
        align-items: baseline;
        gap        : 1rem;
    }

    .dropdown-menu {
        background-color: $primary;
        color           : $lightText;
        border          : 0px solid $info;
        border-radius   : 0.25rem;
    }

    .nav-item,
    .nav-item>a {
        color: $primary;
    }

    .nav-item>a{
        padding: 6px;
    }

    .dropdown-item,
    .dropdown-item>a {
        color: $lightText;
    }

    .nav-item:hover >a  {
        background-color: $primary;
        color           : $lightText;
       
    }

    .dropdown-item:hover,
    .dropdown-item>a:hover {
        background-color: $secondary;
        color           : $primary;
    }

    @media (min-width: 768px) {
        .navbar-expand-md .navbar-nav {
            gap: 1rem;
        }
    }
}