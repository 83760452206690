.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.register-from{
  width:25rem;
}

.register_form_container{
  width:100%;
  display: flex;
  flex-direction: column;
  align-items:center;
  h2{
    width: fit-content;
    margin-bottom: 2rem;
  }
}

.error{
  color: $danger  
}

.btn-primary{
  background-color: $hightlight-bg;
  color: $light-text;
  border-radius: 0;
  font-size: 1.2rem;
  border-color: $hightlight-bg;
  border-width: 1px;
}

.btn-primary:hover{
  background-color: $dark-primary;
  border-color: $hightlight-bg;
}

.btn-warning,
.btn-warning:active{
  background-color: $danger;
  color: $dark-text;
  border-radius: 0;
  font-size: 1.2rem;
  border-color:$dark-danger;
  border-width: 1px;
}

.btn-warning:hover{
  background-color:$dark-danger;
  border-color: $danger;
  color: $light-text
}

.editable-lable-group {
  width      : fit-content;
  align-items: bottom;
}

.editable-label-multi-label{
  margin-right:1rem;
}

.form-select-50{
  width:auto;
}

.compund-form-field{
  display: flex;
  flex-direction: row;
  gap:1rem;
}