.overlay {
    position        : fixed;
    height          : 100%;
    width           : 100%;
    top             : 0;
    left            : 0;
    background-color: $dialog_overlay_background;
    display         : flex;
    justify-content : center;
    align-items     : center;
    z-index         : 999;
}

.overlay_inner {
    background-color: #ffffff;
    padding         : 3rem;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
}

.webcam_div {
    display       : flex;
    flex-direction: column;
}

.editable-image {
    width: fit-content;
}