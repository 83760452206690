.login_container{
    width:100%;
  
  }
  .login_form{
    margin-left: auto;
    margin-right: auto;
    max-width: 25em;
  }

  .login_error{
    color:$danger;
  }