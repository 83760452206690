.add_participant_row{
    display: grid;
    grid-template-columns: repeat(2, auto) 2rem;
    gap:2rem;
    align-items: center;
}

.exam-action-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:2rem;
}

.participant_row{
    display: grid;
    grid-template-columns: repeat(3, auto) 4rem 4rem 2rem;
    gap:2rem;
    align-items: center;
}

.exam-addstudent{
    margin-top: 2rem;
}